import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'hb-setup',
  templateUrl: './setup.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SetupComponent {}
